import { plainToInstance } from "class-transformer";
import { TokenResponse } from "services/borbalo-main.service";
import { TokensModel } from "services/tokens.model";

export const setTokensDataToKeychain = async (data: TokenResponse) => {
  await localStorage.setItem(
    "auth",
    JSON.stringify({
      ...data,
      access_token_expire_time:
        Math.ceil(new Date().getTime() / 1000) + data.expires_in,
    }),
  );
};

export const getTokensFromStorage = async () => {
  const tokensJson = await localStorage.getItem("auth");

  const password: TokensModel | false = tokensJson && JSON.parse(tokensJson);

  if (password) {
    return plainToInstance(TokensModel, password);
  }

  return null;
};
